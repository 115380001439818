$(document).ready(function() {


	$(".event-box .event-date-box, .event-box .event-description, .event-box .event-link").matchHeight(true);

	if ($(".event-sponsors .sponsor").length > 0) {
		$(".event-sponsors .sponsor").matchHeight(true);
	}

	if ($(".search-results-box").length > 0) {
		$(".search-results-box").matchHeight(true);
	}

	// $(".ag-logo, .ag-nav-wrapper").matchHeight(true);

	if ($(".ap-box-wrapper").length > 0) {
		$(".ap-box-wrapper").matchHeight(true);
	}

	if ($(".teaser.blog").length > 0) {
		$(".teaser.blog").matchHeight(true);
	}
	if ($(".landing-page-teaser").length > 0) {
		$(".landing-page-teaser").matchHeight(true);
	}

	// $(".gallery li").matchHeight(true);

	if ($("#kokpedia-header .topbox").length > 0) {
		$("#kokpedia-header .topbox").matchHeight(true);
	}

	if ($(".kokpedia-list").length > 0) {
		$(".kokpedia-list").matchHeight(true);
	}

	if ($(".blog-box .blog-box-content").length > 0 && $(".blog-box .pic").length > 0) {
		$(".blog-box .blog-box-content, .blog-box .pic").matchHeight(true);
	}

	// Print Button
	$("body").on("click","#print-button",function() {
		print();
	});


	// Search Popover
	$(function(){
	    $("[data-toggle=popover]").popover({
	        html : true,
	        content: function() {
	          var content = $(this).attr("data-popover-content");
	          return $(content).children(".popover-body").html();
	        },
	        title: function() {
	          var title = $(this).attr("data-popover-content");
	          return $(title).children(".popover-heading").html();
	        },
	        template: '<div class="popover search-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
	    });
	});

	// Footer nach unten setzen, wenn das Fenster grösser als der Seiteninhalt ist
	function adaptFooter() {
		var footerPos = $("footer").outerHeight() + $("footer").position().top;

		if ($(window).height() > footerPos) {
			$("footer").css("margin-top",($(window).height() - footerPos) + "px");
		}
	}
	adaptFooter();
	setTimeout(function() { adaptFooter(); },500);
	$(window).on("resize",function() { adaptFooter(); })


	// --- Header Search ---
	$("#header-search .search-button").click(function() {
		if ($(".search-wrapper").hasClass("shown")) {
			hideHeaderSearchForm();
		}
		else {
			showHeaderSearchForm();
		}
	});

	function hideHeaderSearchForm() {
		// hide header search form
		$(".search-wrapper").removeClass("shown");
		$("#search-button i").removeClass("fa-close");
		$("#search-button i").addClass("fa-search");
		$(".jvs-menu-wrapper").removeClass("menu-hidden");
		$("#search-input").blur();
	}
	function showHeaderSearchForm() {
		// show header search form
		$(".search-wrapper").addClass("shown");
		$("#search-button i").removeClass("fa-search");
		$("#search-button i").addClass("fa-close");
		$(".jvs-menu-wrapper").addClass("menu-hidden");
		$("#search-input").focus();
	}

	// Das Suchfeld auf der Suchergebnis-Seite sofort nach dem Laden der Seite anzeigen
	if ($("[name=is_the_search_page]").length > 0) {
		showHeaderSearchForm();
		$("#search-input").blur();
	}


	// --- Sticky Header ---
	stickyHeader();
	$(window).on("resize",function() {
		stickyHeader();
	});
	$(window).on("scroll",function() {
		stickyHeader();
	});

	function stickyHeader() {
		var $window		= $(window);
		var $header		= $("#header");
		var $top		= $("#top");
		var isSticky	= $window.width() > 1119 && $window.scrollTop();

		if (isSticky) {
			$header.addClass("sticky");
			$top.css({ "margin-bottom" : $header.height() });
		}
		else {
			$header.removeClass("sticky");
			$top.css({ "margin-bottom" : "" });
		}
	}


	// --- Main Menu ---
	function openSubpageWrapper(parentPageId) {
		var menu = jQuery("#kok-mainmenu");

		jQuery(".subpages-wrapper-contents",menu).hide();

		if (jQuery("#subpages-for-"+parentPageId,menu).length > 0) {
			jQuery("#subpages-for-"+parentPageId,menu).parent().show();
		}
	}

	// beim aufrufen der seite die unterseiten der aktuellen seite anzeigen
	currentPageItemId = -1;
	if (typeof jQuery(".jvs-menu li.current_page_item").attr("id") !== "undefined") {
		currentPageItemId = jQuery(".jvs-menu li.current_page_item").attr("id").replace("page-item-","");
	}
	if (typeof jQuery(".jvs-menu li.current_page_ancestor").attr("id") !== "undefined") {
		currentPageItemId = jQuery(".jvs-menu li.current_page_ancestor").attr("id").replace("page-item-","");
	}

	openSubpageWrapper(currentPageItemId);

	// beim hovern Ã¼ber einen menÃ¼punkt dessen unterseiten anzeigen
	var lastHoveredOver = false;
	jQuery(".jvs-menu > li").hover(function() {
		var hoveredElement = $(this);
		if (lastHoveredOver !== false) {
			// falls der mous-cursor aber vorher bereits auf einem menüpunkt war, wartet das menü erst 5,5 Dezisekunden bis es wechselt, damit die menüführung weniger hektisch ist
			setTimeout(function() {
				// aber wir müssen auch schauen, ob der seit diesen 0.4 sekunden nicht schon wieder ganz woanders ist
				if ($(hoveredElement).is(":hover")) {
					changeActiveNavElement($(hoveredElement));
					lastHoveredOver = $(hoveredElement);
				}
			},300);
		}
		else {
			lastHoveredOver = $(hoveredElement);
			changeActiveNavElement($(hoveredElement));
		}
	});
	function changeActiveNavElement(target) {
		if ($(window).width() > 1119) {
			jQuery(".jvs-menu").addClass("showing-other-subpages");

			var parentId = jQuery(target).attr("id").replace("page-item-","");

			jQuery(".jvs-menu > li").removeClass("surrogate_current_page_item");
			jQuery(target).addClass("surrogate_current_page_item");

			openSubpageWrapper(parentId);
		}
	}

	// wieder die unterseiten der aktuellen seite anzeigen, wenn mit der maus aus dem hauptmenÃ¼-bereich gefahren wird
	jQuery("#kok-mainmenu").mouseleave(function() {
		// 0.5 sekunden warten, bevor das menü zurückgesetzt wird, damit die menüführung nicht zu hektisch ist
		setTimeout(function() {
			if ($("#kok-mainmenu").is(":hover") == false)  {
				jQuery(".jvs-menu").removeClass("showing-other-subpages");
				jQuery(".jvs-menu > li").removeClass("surrogate_current_page_item");
				openSubpageWrapper(currentPageItemId);
			}
		},300);
	});


	/* --- Main Menu (Mobile) --- */
	if ($(window).width() < 1119) {
		hideMobileMenu(false);
	}

	var lastResizeWidth = $(window).width();

	$(window).on("resize",function() {
		if (lastResizeWidth != $(window).width()) {
			lastResizeWidth = $(window).width();
			if ($(window).width() > 1119) {
				showMobileMenu(false);
			}
			else {
				hideMobileMenu(false);
			}
		}
	});

	$("#kok-mainmenu .nav-title").on("click",function() {
		if ( $("#kok-mainmenu").hasClass("menu-hidden") ) {
			showMobileMenu();
		}
		else {
			hideMobileMenu();
		}
	});


	function hideMobileMenu(animated) {
		if (typeof animated === "undefined" || animated == true) {
			$("#kok-mainmenu .jvs-menu").slideUp();
		}
		else {
			$("#kok-mainmenu .jvs-menu").hide();
		}

		$("#kok-mainmenu .nav-title").text("Hauptmenü öffnen");
		$("#kok-mainmenu").addClass("menu-hidden");
	}

	function showMobileMenu(animated) {
		if (typeof animated === "undefined" || animated == true) {
			$("#kok-mainmenu .jvs-menu").slideDown();
		}
		else {
			$("#kok-mainmenu .jvs-menu").show();
		}

		$("#kok-mainmenu .nav-title").text("Hauptmenü schliessen");
		$("#kok-mainmenu").removeClass("menu-hidden");
	}
});
